import * as Sentry from "@sentry/astro";

const dsn = import.meta.env.PUBLIC_SENTRY_DSN

Sentry.init({
  dsn,
  denyUrls: [
    /googletagmanager\.com/
  ],
  ignoreErrors: ["Cannot read properties of undefined (reading 'reachGoal')", /google_tag_manager/i]
});
